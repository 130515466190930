<template>
    <section>
        <tabs :tabs="rutas" class="border-bottom pt-2" />
        <router-view />
    </section>
</template>

<script>
import confirmacionPrecio from  '~/services/retiros/confirmacion_precio'
import { mapGetters } from 'vuex'
export default {
    data(){
        return {
            retirosProductosCedis: 0,
            retirosProductosTenderos: 0,
        }
    },
    computed: {
        ...mapGetters({
            id_cedis:'cedis/id_cedis',
            id_moneda:'cedis/id_moneda',
        }),
        rutas(){
            return [
                { titulo: 'Retiro de cedis a cedis', ruta: 'admin.retiros.cedis', num: this.retirosProductosCedis, can: 'tab2_gestion_admin_retiros_cedis' },
                { titulo: `Retiros de ${this.$config.vendedor} a cedis`, ruta: 'admin.retiros.leecheros', num: this.retirosProductosTenderos, can: 'tab2_gestion_admin_retiros_leechero' },
                { titulo: 'Aprobacion de retiros', ruta: 'admin.retiros.aprobados', can: 'tab2_gestion_admin_retiros_aprobados' },
            ]
        }
    },
    watch: {
        id_moneda(val,oldVal){
            if (oldVal == null) return
            if(val == null) return
            this.montar()
        },
        id_cedis(val,oldVal){
            if (oldVal == null) return
            if(val == null) return
            this.montar()
        }
    },
    mounted(){
        this.montar()
    },
    methods: {
        async montar(){
            try {
                if(this.id_cedis === null || this.id_moneda === null){
                    setTimeout(async() => {
                        await this.indexContarRetiros()
                    }, 1000);
                }else{
                    await this.indexContarRetiros()
                }
            } catch (error){
                this.error_catch(error)
            }finally{
            }
        },
        async indexContarRetiros(){
            try {
                const params = {
                    id_cedis: this.id_cedis,
                    id_moneda: this.id_moneda,
                }
                const { data } = await confirmacionPrecio.indexContarRetiros(params);
                this.retirosProductosCedis = data.retirosProductosCedis
                this.retirosProductosTenderos = data.retirosProductosTenderos
            } catch (error){
                this.error_catch(error)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
</style>
